import { configureStore } from '@reduxjs/toolkit'
import transactionsSlice from '../transactions/slices/transactionsSlice';
import appSlice from '../app/slices/appSlice';
import timeReportSlice from '../time-report/slices/timeReportSlice';
import projectSlice from '../projects/slices/projectSlice';
import employeeSlice from '../employees/slices/employeeSlice';
import authenticationSlice from './authenticationSlice';
import vismaImportSlice from '../visma-import/slices/vismaImportSlice';


export const store = configureStore({
    reducer: {
        app: appSlice,
        project: projectSlice.reducer,
        transaction: transactionsSlice.reducer,
        timeReport: timeReportSlice.reducer,
        employee: employeeSlice.reducer,
        authentication: authenticationSlice.reducer,
        vismaImport: vismaImportSlice.reducer
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch