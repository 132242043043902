import { Transaction } from '../types';

export const getTransactions = (jwtToken: string, email: string, year?: number, month?: number, description?: string) => {
  let queries = [];
  if (year) {
    queries.push(`year=${year}`);
  }
  if (month) {
    queries.push(`month=${month}`);
  }
  if (description) {
    queries.push(`description=${description}`);
  }
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${email}/transaction${queries.length ? "?" + queries.join("&") : ""}`, {
    headers: { authorization: `bearer ${jwtToken}` },
  }).then((res: any) => res.json());
};

export const getTransactionsMeta = (jwtToken: string, email: string) => {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${email}/transaction/meta`, {
    headers: { authorization: `bearer ${jwtToken}` },
  }).then((res: any) => res.json());
};

export const getYearlyOverview = (jwtToken: string, year: number) => {
  console.log('getYearlyOverview', year);
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/yearlyoverview?year=${year}`, {
    headers: { authorization: `bearer ${jwtToken}` },
  }).then((res: any) => res.json());
};

export const postTransaction = (jwtToken: string, email: string, transaction: Transaction) => {
  console.log('post trans');
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${email}/transaction`, {
    method: 'POST',
    body: JSON.stringify(transaction),
    headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  }).then((res: any) => res.json());
};

export const deleteTransaction = (jwtToken: string, email: string, transaction: Transaction) => {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${email}/transaction/${transaction.id}`, {
    method: 'DELETE',
    headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  }).then((res: any) => res.json());
};

export const updateTransaction = async (jwtToken: string, transaction: Transaction) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${transaction.email}/transaction/${transaction.id}`, {
    method: 'PUT',
    body: JSON.stringify(transaction),
    headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  });
  return res.json();
};
