import dateformat from "dateformat";
import {
  Table
} from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../app/slices/appSlice";
import MoreMenu from "../common/MoreMenu/MoreMenu";
import { StyledTableRow } from '../styles/TableStyles';
import { TimeReport } from "../types";
import { tableCellsMaxWidths } from "./layout";
import timeReportSlice from "./slices/timeReportSlice";
type TimeReportRowType = {
  timereport: TimeReport;
  onRemove: (timereport: TimeReport) => any;
  isAdmin: boolean;
};

const TimeReportRow = ({
  timereport,
  onRemove,
  isAdmin,
}: TimeReportRowType) => {
  const dispatch = useDispatch();
  const projects = useSelector((state: any) => state.app.projects); // flytta ut till View

  const project = projects.find(
    (project: Project) => timereport.project_id === project.id
  );

  return (
    <StyledTableRow key={timereport.id}>
      {!isMobile && (
        <>
          <Table.TextCell maxWidth={tableCellsMaxWidths.date}>
            {dateformat(timereport.time, "yyyy-mm-dd")}
          </Table.TextCell>
          <Table.TextCell>{timereport.description}</Table.TextCell>
        </>
      )}
      {isMobile && (
        <>
          <Table.TextCell>
            <p>
              {dateformat(timereport.time, "yyyy-mm-dd")}
              <br />
              {timereport.description}
            </p>
          </Table.TextCell>
        </>
      )}
      {
        <>
          <Table.TextCell maxWidth={tableCellsMaxWidths.hours} isNumber>
            {new Intl.NumberFormat("sv-SE").format(timereport.hours)}
          </Table.TextCell>
          <Table.TextCell maxWidth={tableCellsMaxWidths.project}>
            {project.project_name}
          </Table.TextCell>
          <Table.Cell
            maxWidth={tableCellsMaxWidths.options}
            justifyContent="right"
            width="10px"
          >
            {isAdmin && (
              <MoreMenu
                onEdit={() =>
                  dispatch(timeReportSlice.actions.editMode(timereport.id))
                }
                onRemove={() => onRemove(timereport)}
              ></MoreMenu>
            )}
          </Table.Cell>
        </>
      }
    </StyledTableRow>
  );
};

export default TimeReportRow;
