import { Button, Pane, Spinner } from "evergreen-ui"
import { useDispatch, useSelector } from "react-redux"
import { ViewWrapper } from "../common/ViewWrapper"
import { LoadingPane } from '../styles/TableStyles'
import { Employee } from "../types"
import isAdminSelector from "../utils/isAdminSelector"
import EmployeeGrid from "./EmployeeGrid"
import employeeSlice from "./slices/employeeSlice"
type TimeReportViewType = {
  jwtToken: string
  user: Employee
}

const EmployeeView = ({ jwtToken, user }: TimeReportViewType) => {
  //j compile fail when removing parameters
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)
  const btnStatus = useSelector(
    (state: any) => state.employee.disableAddEmployeeBtn
  )

  const isLoading: boolean =
    useSelector((state: any) => state.employee.loadingEmployees) === "pending"
    const doneLoading: boolean =
    useSelector((state: any) => state.employee.loadingEmployees) === "succeeded"

  const renderButtons = () =>
    isAdmin && (
      <Button
        height={32}
        appearance="primary"
        disabled={btnStatus || !doneLoading}
        intent="success"
        onClick={() =>
          dispatch(employeeSlice.actions.toggleShowAddNewEmployee())
        }
      >
        Lägg till
      </Button>
    )

  return (
    <ViewWrapper title="Anställda" renderButtons={renderButtons}>
      <Pane marginTop="20px" display="block">
        {isLoading ? (
          <LoadingPane>
            <Spinner margin="auto" />
          </LoadingPane>
        ) : (
          <EmployeeGrid />
        )}
      </Pane>
    </ViewWrapper>
  )
}

export default EmployeeView
