import { Employee } from "../types";

export const getEmployees = async (jwtToken: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee`, {
    headers: { authorization: `bearer ${jwtToken}` },
  });
  return res.json();
};

export const postEmployee = async (jwtToken: string, employee: Employee) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee`, {
    method: 'POST',
    body: JSON.stringify(employee),
    headers: {authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json'}
  })
  return res.json()
}

export const updateEmployee = async (jwtToken: string, employee: Employee) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee/${employee.id}`, {
  method: 'PUT',
  body: JSON.stringify(employee),
  headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  })
  return res.json()
}