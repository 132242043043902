import { CaretDownIcon, CaretUpIcon, Table } from "evergreen-ui"
import "react-datepicker/dist/react-datepicker.css"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { SortingHeaderCell, TableWrapper } from '../styles/TableStyles'
import EditEmployeeRow from "./EditEmployeeRow"
import EmployeeRow from "./EmployeeRow"
import employeeSlice from "./slices/employeeSlice"

const EmployeeGrid = () => {
  const employees = useSelector((state: any) => state.employee.employees)
  const sort = useSelector((state: any) => state.employee.sort)
  const dispatch = useDispatch()
  return (
    <TableWrapper>
      <Table>
        <Table.Head padding="0px">
          {!isMobile && (
            <>
              <SortingHeaderCell
                onClick={() => dispatch(employeeSlice.actions.toggleSort())}
              >
                Förnamn
                {sort === "desc" ? (
                  <CaretUpIcon size={14} />
                ) : (
                  <CaretDownIcon size={14} />
                )}
              </SortingHeaderCell>
            </>
          )}
          {isMobile && <Table.TextHeaderCell>Förnamn</Table.TextHeaderCell>}
          <Table.TextHeaderCell>Efternamn</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell>Status</Table.TextHeaderCell>
          <Table.TextHeaderCell></Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {employees &&
            employees.map((employee: any) =>
              employee.editMode ? (
                <EditEmployeeRow employee={employee} key={employee.id} />
              ) : (
                <EmployeeRow employee={employee} key={employee.id} />
              )
            )}
        </Table.Body>
      </Table>
    </TableWrapper>
  )
}

export default EmployeeGrid
