import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import {
  getEmployees,
  postEmployee,
  updateEmployee,
} from "../../api/crudEmployees"
import { Employee } from "../../types/index"

export const saveEmployee: any = createAsyncThunk<any, Employee>(
  "employee/saveEmployee",
  async (employee: Employee, thunkAPI) => {
    const state: any = thunkAPI.getState()
    const response = await postEmployee(
      state.authentication.jwtIdToken,
      employee
    )

    return response
  }
)

export const fetchEmployees: any = createAsyncThunk(
  "employees/fetch",
  async (data, thunkAPI) => {
    const state: any = thunkAPI.getState()
    return await getEmployees(state.authentication.jwtIdToken)
  }
)

export const saveUpdatedEmployee: any = createAsyncThunk<any, Employee>(
  "employees/saveUpdatedEmployee",
  async (employee: Employee, thunkAPI) => {
    const state: any = thunkAPI.getState()
    const response = await updateEmployee(
      state.authentication.jwtIdToken,
      employee
    )
    return response
  }
)

interface EmployeeState {
  employees: Employee[]
  disableAddEmployeeBtn: boolean
  loadingEmployees: string
  loadingEmployee: string
  sort: string
}

const employeeSlice = createSlice({
  name: "employees",
  initialState: {
    employees: [],
    disableAddEmployeeBtn: false,
    loadingEmployees: "idle",
    loadingEmployee: "idle",
    sort: "asc",
  } as EmployeeState,
  reducers: {
    toggleShowAddNewEmployee(state) {
      const newEmployee = {
        id: -1,
        email: "",
        firstname: "",
        lastname: "",
        active: true,
        editMode: true,
      }
      state.disableAddEmployeeBtn = true
      state.employees.unshift(newEmployee)
    },

    toggleSort(state) {
      state.sort = state.sort === "asc" ? "desc" : "asc"
      state.employees =
        state.sort === "asc"
          ? state.employees.sort((a, b) =>
              a.firstname.localeCompare(b.firstname)
            )
          : state.employees.sort(
              (a, b) => -1 * a.firstname.localeCompare(b.firstname)
            )
    },

    cancelNew(state, action: PayloadAction<number>) {
      if (action.payload === -1) {
        state.disableAddEmployeeBtn = false
        state.employees = [...state.employees].filter(
          (employee) => employee.id !== -1
        )
      } else {
        state.employees.forEach((employee) =>
          employee.editMode && employee.id === action.payload
            ? delete employee.editMode
            : null
        )
      }
    },

    editMode(state, action: PayloadAction<number>) {
      state.employees.map((employee, i) => {
        if (employee.id === action.payload) {
          state.employees[i] = { ...employee, editMode: true }
        }
      })
    },
  },

  extraReducers: {
    [saveEmployee.pending]: (state) => {
      state.loadingEmployee = "pending"
    },
    [saveEmployee.fulfilled]: (state, action) => {
      state.disableAddEmployeeBtn = false
      state.employees = [...state.employees, action.payload].filter(
        (employee) => employee.id !== -1
      )
      state.employees =
        state.sort === "asc"
          ? state.employees.sort((a, b) =>
              a.firstname.localeCompare(b.firstname)
            )
          : state.employees.sort(
              (a, b) => -1 * a.firstname.localeCompare(b.firstname)
            )
      state.loadingEmployee = "succeeded"
    },

    [fetchEmployees.pending]: (state) => {
      state.loadingEmployees = "pending"
    },
    [fetchEmployees.fulfilled]: (state, action) => {
      state.employees = action.payload
      state.loadingEmployees = "succeeded"
    },

    [saveUpdatedEmployee.pending]: (state) => {
      state.loadingEmployee = "pending"
    },
    [saveUpdatedEmployee.fulfilled]: (state, action) => {
      state.employees = [...state.employees, action.payload].filter(
        (employee) => !employee.editMode || employee.id !== action.payload.id
      )
      state.employees =
        state.sort === "asc"
          ? state.employees.sort((a, b) =>
              a.firstname.localeCompare(b.firstname)
            )
          : state.employees.sort(
              (a, b) => -1 * a.firstname.localeCompare(b.firstname)
            )
      state.loadingEmployee = "succeeded"
    },
  },
})

export default employeeSlice
