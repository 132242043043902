import { Pane, Combobox } from "evergreen-ui";
import { TabFilter } from "../TabFilter";
import { DateFilter } from "../../types/index";
import { allMonths, YearMonths } from "./types";
import { Wrapper, TabContainer } from './styles';
import { findAllByDisplayValue } from "@testing-library/react";

type TimespanSelectorProps = {
  onChange: (filter: DateFilter) => void;
  yearMonths: YearMonths;
  filter: DateFilter;
  selectAllMonth?: boolean;
};

export const TimespanSelector = ({
  onChange,
  yearMonths,
  filter,
  selectAllMonth = true
}: TimespanSelectorProps) => {

  const years = yearMonths && Object.keys(yearMonths);

  // TODO - currentYear skall inte vara hårdkodat..
  const currentYear = 2021;

  // TODO - varför har vi hårdkodat 2021 här ?? 
  const months = (yearMonths && yearMonths[filter.year]) ?? (yearMonths && yearMonths[currentYear]);
  const selectedYear = filter.year ?? currentYear;

  const selectedMonth = filter.month ?? 0;
  const selectedMonthIndex = months && months.indexOf(selectedMonth); //TODO: Har kraschat här

  return (
    <Wrapper
      clearfix
    >
      <TabContainer>
        {years && <Combobox
          initialSelectedItem={{ label: selectedYear.toString() }}
          items={years.map(year => ({ label: year }))}
          itemToString={item => (item ? item.label : '')}
          onChange={selected => onChange({ ...filter, year: Number(selected.label) })}
        />

        }
      </TabContainer>
      <TabContainer
        float="right"
      >
        {months && <TabFilter
          selectAllOption={selectAllMonth}
          items={months.map(month => allMonths[month - 1])}
          onSelect={(index: number, value: any) => onChange({ ...filter, month: allMonths.indexOf(value) + 1 })}
          selectedIndex={selectedMonthIndex}
        />}
      </TabContainer>
    </Wrapper>
  );
};
