import { useState, } from "react";
import { Table, Button, IconButton, TrashIcon, CrossIcon, majorScale, EyeOffIcon, FloppyDiskIcon, UndoIcon } from "evergreen-ui";
import { Transaction } from "../types";
import styled from "styled-components";
import dateformat from "dateformat";
import { isMobile } from "react-device-detect";
import { TransactionStatus } from '../types';

import "react-datepicker/dist/react-datepicker.css";

type PreliminaryTransactionRowType = {
  transaction: Transaction;
  onUndo: (transaction: Transaction) => any;
  onSave: (transaction: Transaction) => any;
};

const StyledTableRow = styled(Table.Row)`
  background-color: #FFFAD0;
  &:hover > div {
    background-color: #efefef;
  }
`;

const PreliminaryTransactionRow = ({
  transaction,
  onUndo,
  onSave
}: PreliminaryTransactionRowType) => {

  const saveAsRejected = () => {
    onSave({ ...transaction, status: TransactionStatus.Rejected })
  }

  const saveAsFinal = () => {
    onSave({ ...transaction, status: TransactionStatus.Final })
  }

  const renderMenu = () => (
    <>
      <IconButton icon={EyeOffIcon} intent="danger" marginLeft={majorScale(1)} onClick={() => saveAsRejected()} />
      <IconButton icon={FloppyDiskIcon} intent="success" marginLeft={majorScale(1)} onClick={() => saveAsFinal()} />
      <IconButton icon={UndoIcon} intent="none" marginLeft={majorScale(1)} onClick={() => onUndo(transaction)} />
    </>
  )

  return (
    <StyledTableRow
      key={transaction.id}
    >
      {!isMobile && (
        <>
          <Table.TextCell maxWidth="125px">
            {dateformat(transaction.time, "yyyy-mm-dd")}
          </Table.TextCell>
          <Table.TextCell>{transaction.description}</Table.TextCell>
        </>
      )}
      {isMobile && (
        <>
          <Table.TextCell>
            <p>
              {dateformat(transaction.time, "yyyy-mm-dd")}
              <br />
              {transaction.description}
            </p>
          </Table.TextCell>
        </>
      )}
      {<><Table.TextCell isNumber>
        {transaction.amount && new Intl.NumberFormat("sv-SE").format(Math.round(transaction.amount))}
      </Table.TextCell>
        <Table.TextCell isNumber>

        </Table.TextCell>
        <Table.Cell justifyContent="right" width="10px">
          {
            renderMenu()
          }
        </Table.Cell></>}

    </StyledTableRow>
  )
};

export default PreliminaryTransactionRow;
