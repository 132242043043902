import dateformat from "dateformat";
import { Table } from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import MoreMenu from "../common/MoreMenu/MoreMenu";
import { StyledTableRow } from '../styles/TableStyles';
import { Transaction } from "../types";
import transactionsSlice from "./slices/transactionsSlice";
type TransactionRowType = {
  transaction: Transaction;
  onRemove: (transaction: Transaction) => any;
  isAdmin: boolean;
};

const TransactionRow = ({
  transaction,
  onRemove,
  isAdmin
}: TransactionRowType) => {
  const dispatch = useDispatch()

  return (
    <StyledTableRow
      key={transaction.id}
    >
      { !isMobile && (
        <>
          <Table.TextCell maxWidth="125px">
            {dateformat(transaction.time, "yyyy-mm-dd")}
          </Table.TextCell>
          <Table.TextCell>{transaction.description}</Table.TextCell>
        </>
      )}
      { isMobile && (
        <>
          <Table.TextCell>
            <p>
              {dateformat(transaction.time, "yyyy-mm-dd")}
              <br />
              {transaction.description}
            </p>
          </Table.TextCell>
        </>
      )}
      { <><Table.TextCell isNumber>
        {transaction.amount && new Intl.NumberFormat("sv-SE").format(Math.round(transaction.amount))}
      </Table.TextCell>
        <Table.TextCell isNumber>
          {new Intl.NumberFormat("sv-SE").format(Math.round(Number(transaction.sum)))}
        </Table.TextCell>
        <Table.Cell justifyContent="right" width="10px">
          {
           isAdmin && <MoreMenu onEdit={()=> dispatch(transactionsSlice.actions.editMode(transaction.id!))} onRemove={()=> onRemove(transaction)}></MoreMenu>
          }
        </Table.Cell></>}

    </StyledTableRow>
  )
};

export default TransactionRow;
