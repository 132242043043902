import { CaretDownIcon, CaretUpIcon, Table } from "evergreen-ui"
import "react-datepicker/dist/react-datepicker.css"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { SortingHeaderCell, TableWrapper } from '../styles/TableStyles'

const formatNumber = (num: any) => { return new Intl.NumberFormat("sv-SE").format(Math.round(Number(num)))}
const getDiffSum = (yearlyOverview: any[]) => yearlyOverview.reduce((prev, curr) => prev + Number(curr.endSum) - Number(curr.startSum), 0)

const YearlyOverviewGrid = ({ yearlyOverview }: { yearlyOverview: any[] }) => {

console.log(yearlyOverview);

  return (
    <TableWrapper>
      <Table>
        <Table.Head padding="0px">
          <Table.TextHeaderCell>E-post</Table.TextHeaderCell>
          <Table.TextHeaderCell>Ingångsvärde (2022-01-01)</Table.TextHeaderCell>
          <Table.TextHeaderCell>Utgångsvärde (2022-12-31)</Table.TextHeaderCell>
          <Table.TextHeaderCell>Diff</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {yearlyOverview &&
            yearlyOverview.map((item: any) =>
              <Table.Row key={item.email}>
                <Table.Cell>{item.email.split(".")[0]}</Table.Cell>
                <Table.Cell>{formatNumber(item.startSum)}</Table.Cell>
                <Table.Cell>{formatNumber(item.endSum)}</Table.Cell>
                <Table.Cell>{formatNumber(item.endSum - item.startSum)}</Table.Cell>
              </Table.Row>
            )}
        </Table.Body>
        <Table.Head padding="0px">
          <Table.TextHeaderCell>Summa</Table.TextHeaderCell>
          <Table.TextHeaderCell></Table.TextHeaderCell>
          <Table.TextHeaderCell></Table.TextHeaderCell>
          <Table.TextHeaderCell>{formatNumber(getDiffSum(yearlyOverview))}</Table.TextHeaderCell>
        </Table.Head>
      </Table>
    </TableWrapper>
  )
}

export default YearlyOverviewGrid;
