import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { postTransaction } from "../../api/crudTransactions"
import {
  getVismaImport
} from "../../api/getVismaImport"
import { RootState } from "../../app/store"
import { Transaction, TransactionStatus } from "../../types"

export const fetchVismaImport: any = createAsyncThunk(
  "vismaimport/fetch",
  async (data: VismaImportFilter, thunkAPI) => {
    const state: any = thunkAPI.getState()
    return await getVismaImport(
      state.authentication.jwtIdToken,
      data.year,
      data.month,
      data.status
    )
  }
)

export const saveTransaction: any = createAsyncThunk(
  "vismaimport/save",
  async (importItem: VismaImportItem, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const response = await postTransaction(
      state.authentication.jwtIdToken, importItem.email,
      { ...importItem, email: importItem.email, status: TransactionStatus.Final }
    );
  }
)

type VismaImportFilter = {
  year: number,
  month: number,
  status: "PENDING" | "IMPORTED" | "REJECTED"
}

export type VismaImportItem = Transaction & { selected: boolean, importStatus: "PENDING" | "SAVING" | "IMPORTED" | "REJECTED" | "HIDDEN", name: string };

type VismaImportState = {
  filter: VismaImportFilter,
  isLoading: boolean,
  items: VismaImportItem[],
  sorting: {
    field: "PBR" | "Date",
    order: "ASC" | "DESC"
  }
}
const pendingFilter = (item: any) => item.importStatus === "PENDING";
export const isAllSelectedSelector = (state: RootState) => state.vismaImport.items.filter(pendingFilter).length && !state.vismaImport.items.filter(pendingFilter).find(item => !item.selected);

const vimsaImportSlice = createSlice({
  name: "employees",
  initialState: {
    filter: {
      year: (new Date()).getFullYear(),
      month: (new Date()).getMonth() + 1,
      status: "PENDING"
    },
    sorting: {
      field: "Date",
      order: "ASC"
    },
    isLoading: false,
    items: []
  } as VismaImportState,
  reducers: {
    setMonth: (state, action: PayloadAction<number>) => {
      state.filter.month = action.payload;
    },
    setYear: (state, action: PayloadAction<number>) => {
      state.filter.year = action.payload;
    },
    setStatus: (state, action: PayloadAction<"PENDING" | "IMPORTED" | "REJECTED">) => {
      state.filter.status = action.payload;
    },
    toggleSelectItem: (state, action: PayloadAction<string | undefined>) => {
      const item = state.items.find(transaction => transaction.sourceReference === action.payload);
      if (item) {
        item.selected = !item.selected;
      }
    },
    toggleSelectAllItems: (state) => {
      const selectableItems = state.items.filter(item => item.importStatus === "PENDING");
      if (selectableItems.find(item => !item.selected)) {
        //If some is unselected -> select all
        selectableItems.forEach(item => item.selected = true);
      } else {
        //If all is selected -> unselect all
        selectableItems.forEach(item => item.selected = false);
      }

    },
    sortAndToggleOrder: (state, action: PayloadAction<"PBR" | "Date">) => {
      state.sorting.field = action.payload;
      state.sorting.order = state.sorting.order === "DESC" ? "ASC" : "DESC";
      state.items.sort((a, b) => {
        if (state.sorting.field === "PBR") {
          return a.name > b.name && state.sorting.order === "ASC" ? 1 : -1;
        } else if (state.sorting.field === "Date") {
          return a.time > b.time && state.sorting.order === "ASC" ? 1 : -1;
        }
        return 1;
      })
    }
  },
  extraReducers: {
    [fetchVismaImport.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchVismaImport.fulfilled]: (state, action) => {
      state.items = action.payload.map((item: VismaImportItem) => ({ ...item, selected: false, importStatus: "PENDING" }))
      state.items.sort((a, b) => {
        if (state.sorting.field === "PBR") {
          return a.name > b.name && state.sorting.order === "ASC" ? 1 : -1;
        } else if (state.sorting.field === "Date") {
          return a.time > b.time && state.sorting.order === "ASC" ? 1 : -1;
        }
        return 1;
      })
      state.isLoading = false;
    },
    [saveTransaction.pending]: (state, action) => {
      const item = state.items.find(item => item.sourceReference === action.meta.arg.sourceReference);
      if (item) {
        item.importStatus = "SAVING";
      }
    },
    [saveTransaction.fulfilled]: (state, action) => {
      const item = state.items.find(item => item.sourceReference === action.meta.arg.sourceReference);
      if (item) {
        item.importStatus = "IMPORTED"
        item.selected = false;
      }
    }
  },
})

export const { setMonth, setYear, setStatus, toggleSelectItem, toggleSelectAllItems, sortAndToggleOrder } = vimsaImportSlice.actions;

export default vimsaImportSlice
