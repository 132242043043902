import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthenticationState = {
  isAdmin: boolean,
  name: string,
  email: string,
  jwtIdToken: string
}

type SetUserPayload = {
  name: string,
  email: string,
  jwtIdToken: string
}

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isAdmin: false
  } as AuthenticationState,
  reducers: {
    setUser(state, action: PayloadAction<SetUserPayload>) {
      state.isAdmin = true;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.jwtIdToken = action.payload.jwtIdToken;
    },
  }
})

export default authenticationSlice;
