import { Tab } from "evergreen-ui";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import timeReportSlice from "../../time-report/slices/timeReportSlice";
import { DateFilterRange } from "../../types";
import { DefaultTab, TabContainer, Wrapper } from './styles';
import { allMonths, YearMonths } from "./types";
type NewTimeSpanSelectorProps = {
  yearMonths: YearMonths;
  filter: DateFilterRange;
};

const NewTimeSpanSelector = ({
  yearMonths,
  filter,
}: NewTimeSpanSelectorProps) => {
  const dispatch = useDispatch();

  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedYear, setSelectedYear] = useState(2022);
  const [selectedMonth, setSelectedMonth] = useState((new Date(filter.start)).getMonth()); // farlig ifall månad inte finns??
  const [months, setMonths] = useState<number[]>([]);
  const [years, setYears] = useState<number[]>([]);

  const setFilter = (dates: DateFilterRange) => {
    dispatch(timeReportSlice.actions.setFilter(dates));
    setShowCalendar(false);

    if (dates.start.getFullYear() === dates.end.getFullYear()) {
    }
  };
  useEffect(() => {
    if (yearMonths[selectedYear]) {
      setMonths(yearMonths[selectedYear]);
    }
    setYears(Object.keys(yearMonths).map((key) => Number(key)));
    
  }, [yearMonths, selectedYear]);

  const setCustomDateRange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setFilter({ start, end });
    }
  };

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
  };
  return (
    <Wrapper
      clearfix
    >
      <TabContainer>
        {years.map((year, index) => (
          <Tab
            key={index}
            isSelected={year === selectedYear}
            onSelect={() => {
              setSelectedYear(year);
              setFilter({
                start: new Date(year, 0, 1),
                end: new Date(year, 11, 31),
              });
            }}
          >
            {year}
          </Tab>
        ))}
      </TabContainer>
      <TabContainer float="right"
      >
        <DefaultTab
          key={-1}
          isSelected={selectedMonth === -1}
          $inverted
          onSelect={() =>
            {setFilter({
              start: new Date(selectedYear, 0, 1),
              end: new Date(selectedYear, 11, 31),
            }); setSelectedMonth(-1)}
          }
        >
          Alla
        </DefaultTab>
        {months.map((month) => (
          <DefaultTab
            isSelected={selectedMonth === month -1}
            key={month}
            onSelect={() => {
              setFilter({
                start: new Date(selectedYear, month - 1, 1),
                end: new Date(
                  selectedYear,
                  month - 1,
                  getDaysInMonth(selectedYear, month)
                ),
              });
              setSelectedMonth(month -1);
            }}
          >
            {allMonths[month - 1]}
          </DefaultTab>
        ))}
        <DefaultTab
          isSelected={selectedMonth === 12}
          onSelect={() => {
            setShowCalendar(!showCalendar);
            setSelectedMonth(12);
          }}
        >
          Anpassat
        </DefaultTab>
      </TabContainer>
      {showCalendar ? (
        <div
          style={{
            position: "absolute",
            right: "0",
            zIndex: 1,
          }}
        >
          <ReactDatePicker
            selected={startDate}
            onChange={setCustomDateRange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      ) : null}
    </Wrapper>
  );
};
export default NewTimeSpanSelector;
