import { Transaction } from '../types';
import dateformat from "dateformat";
import { VismaImportItem } from './slices/vismaImportSlice';
import { Spinner, TickIcon, EyeOffIcon } from 'evergreen-ui';

const renderTransactionRow = (transaction: VismaImportItem, onSelectItem: (transaction: VismaImportItem) => void) => (
  <tr key={transaction.sourceReference} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
    <td className="w-4 p-4">
      {transaction.importStatus === "SAVING" ? <div className="flex items-center"><Spinner size={24} /></div> :
        transaction.importStatus === "IMPORTED" ? <div className="flex items-center"><TickIcon /></div> :
          transaction.importStatus === "HIDDEN" ? <div className="flex items-center"><EyeOffIcon /></div> :
            <div className="flex items-center">
              <input id="checkbox-table-1" type="checkbox" checked={transaction.selected} onChange={() => onSelectItem(transaction)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="checkbox-table-1" className="sr-only">checkbox</label>
            </div>}
    </td>
    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
      {transaction.name}
    </th>
    <td className="px-6 py-4">
      {dateformat(transaction.time, "yyyy-mm-dd")}
    </td>
    <td className="px-6 py-4">
      {transaction.description}
    </td>
    <td className="px-6 py-4">
      {transaction.amount && new Intl.NumberFormat("sv-SE").format(Math.round(transaction.amount))}
    </td>
  </tr>
)

type VismaImportTableProps = {
  transactions: VismaImportItem[],
  isLoading?: boolean,
  onSelectItem: (transaction: VismaImportItem) => void,
  onSelectAllItems: () => void,
  allSelected: boolean,
  onSort: (field: "PBR" | "Date") => void
}

const VismaImportTable = ({ transactions, onSelectItem, onSelectAllItems, allSelected, onSort, isLoading = false }: VismaImportTableProps) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="p-4">
            <div className="flex items-center">
              <input id="checkbox-all" type="checkbox" checked={allSelected} onChange={() => onSelectAllItems()} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
              <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" className="px-6 py-3 w-3">
            <div className="flex items-center">
              PBR
              <a href="#" onClick={() => onSort("PBR")}><svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
            </div>
          </th>
          <th scope="col" onClick={() => onSort("Date")} className="px-6 py-3">
            <div className="flex items-center">
              Datum
              <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
            </div>
          </th>
          <th scope="col" className="px-6 py-3">
            Beskrivning
          </th>
          <th scope="col" className="px-6 py-3">
            Belopp
          </th>
        </tr>
      </thead>
      {isLoading && <tbody>
        <tr>
          <td className="" colSpan={5}><div className="relative overflow-x-auto  sm:rounded-lg">
            <div className="animate-pulse">
              <div className="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
              <div className="h-4 bg-gray-300 mb-6 rounded"></div>
              <div className="h-4 bg-gray-200 mb-6 rounded"></div>
              <div className="h-4 bg-gray-300 mb-6 rounded"></div>
              <div className="h-4 bg-gray-200 mb-6 rounded"></div>
            </div>
          </div>
          </td></tr>
      </tbody>}
      {!isLoading && <tbody>
        {transactions.map(transaction => renderTransactionRow(transaction, onSelectItem))}
      </tbody>}
    </table>

  )
}

export default VismaImportTable;
