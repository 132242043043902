import {
  Table
} from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MoreMenu from "../common/MoreMenu/MoreMenu";
import isAdminSelector from "../utils/isAdminSelector";
import employeeSlice from "./slices/employeeSlice";

const StyledTableRow = styled(Table.Row)`
  &:hover > div {
    background-color: #efefef;
  }
`;

const EmployeeRow = ({ employee }: any) => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector);

  return (
    <StyledTableRow key={employee.id}>
      {
        <>
          <Table.TextCell>{employee.firstname}</Table.TextCell>
          <Table.TextCell>{employee.lastname}</Table.TextCell>
          <Table.TextCell>{employee.email}</Table.TextCell>
          <Table.TextCell>
            {employee.active ? "Aktiv" : "Inaktiv"}
          </Table.TextCell>
          <Table.Cell justifyContent="right" width="10px">
            {isAdmin &&
          <MoreMenu onEdit={()=> dispatch(employeeSlice.actions.editMode(employee.id))}></MoreMenu>}
          </Table.Cell>
        </>
      }
    </StyledTableRow>
  );
};

export default EmployeeRow;
