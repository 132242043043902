import styled from "styled-components";
import {Table, Combobox, Pane} from 'evergreen-ui'; 

export const TableWrapper = styled.div`
  background-color: white;
`;

export const SortingHeaderCell = styled(Table.TextHeaderCell)`
user-select: none;
&:hover {
  cursor: pointer;
}
& svg {
  vertical-align: middle;
}
`;
export const StyledTableRow = styled(Table.Row)`
  &:hover > div {
    background-color: #efefef;
  }
`;

export const StyledCombobox = styled(Combobox)`
input {
  font-weight: inherit;
  background: none;
  color: #696f8c;
  border: 0.2px solid #c1c4d6;
}
button {
  background: none;
  border: 0.2px solid #c1c4d6;
}
`;

export const LoadingPane = styled(Pane)`
    height: 250px;
    display: flex;
    text-align: center;
`;