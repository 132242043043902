
import { Spinner, Table } from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import { LoadingPane, TableWrapper } from "../styles/TableStyles";
import { Transaction, TransactionStatus } from "../types";
import EditTransactionRow from './EditTransactionRow';
import PreliminaryTransactionRow from "./PreliminaryTransactionRow";
import TransactionRow from "./TransactionRow";
import TransactionsHeader from "./TransactionsHeader";
type TransactionsGridType = {
  transactions: Transaction[];
  sort?: "asc" | "desc";
  toggleSort: any;
  updateTransaction: (transaction: Transaction) => any;
  cancelTransaction: (transaction: Transaction) => any;
  saveTransaction: (transaction: Transaction) => any;
  removeTransaction: (transaction: Transaction) => any;
  undoTransaction: (transaction: Transaction) => any;
  saveUpdatedTransaction: (transaction: Transaction) => any;
  setDescriptionFilter: (description: string) => any;
  descriptionFilter?: string;
  isAdmin: boolean;
  isLoading: boolean;
};

const TransactionsGrid = ({
  transactions,
  sort,
  toggleSort,
  updateTransaction,
  cancelTransaction,
  saveTransaction,
  removeTransaction,
  undoTransaction,
  setDescriptionFilter,
  saveUpdatedTransaction,
  descriptionFilter,
  isAdmin,
  isLoading
}: TransactionsGridType) => {
  return (
    <TableWrapper>
      <Table>
        <TransactionsHeader sort={sort} toggleSort={toggleSort} setDescriptionFilter={setDescriptionFilter} descriptionFilter={descriptionFilter} />
        {isLoading ? (
          <LoadingPane>
            <Spinner margin="auto" />
          </LoadingPane>
        ) : !transactions.length ? (
          <>Inga transaktioner</>
        ) : (
          <Table.Body>
            {transactions.map((transaction: Transaction) =>
              transaction.status === TransactionStatus.Preliminary ?
                <PreliminaryTransactionRow key={transaction.id} transaction={transaction} onSave={saveTransaction} onUndo={undoTransaction} />
                : transaction.status === TransactionStatus.New ?
                  <EditTransactionRow key={transaction.id} saveUpdatedTransaction={saveUpdatedTransaction} updateTransaction={updateTransaction} transaction={transaction} saveTransaction={saveTransaction} cancelTransaction={cancelTransaction} /> :
                  <TransactionRow key={transaction.id} transaction={transaction} onRemove={removeTransaction} isAdmin={isAdmin} />
            )}
          </Table.Body>
        )}
      </Table>
    </TableWrapper>
  );
};

export default TransactionsGrid;
