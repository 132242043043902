import { Button, Pane, Spinner } from "evergreen-ui"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import NewTimeSpanSelector from "../common/TimespanSelector/NewTimeSpanSelector"
import { ViewWrapper } from "../common/ViewWrapper"
import { LoadingPane } from '../styles/TableStyles'
import { Employee, TimeReport } from "../types"
import { DateFilterRange } from "../types/index"
import isAdminSelector from "../utils/isAdminSelector"
import timeReportSlice, {
  fetchTimeReportsByUser,
  fetchTimeReportsMeta,
  removeTimeReport,
  saveNewTimeReport
} from "./slices/timeReportSlice"
import TimeReportGrid from "./TimeReportGrid"
type TimeReportViewType = {
  jwtToken: string
  user: Employee
}

const TimeReportView = ({ jwtToken, user }: TimeReportViewType) => {
  const dispatch = useDispatch()

  const isAdmin = useSelector(isAdminSelector)

  const filter: DateFilterRange = useSelector(
    (state: any) => state.timeReport.filter
  )

  const timeReports: TimeReport[] = useSelector(
    (state: any) => state.timeReport.entities
  )

  const showAddNew = () => {
   const date = new Date(); // TODO - kommenterade ut följande som innehåller gammal kodstruktur: const date = defaultDate(filter.year, filter.month) 
    dispatch(timeReportSlice.actions.addNew(date))
  }

  const isLoading: boolean = 
    useSelector((state: any) => state.timeReport.loadingTimeReports) === "loading"

  //DOING - vi tog bort sortedTimeReports unshift() som inte funkar med vårt nya state. Hitta ny lösning för att lösa att timereport i edit-mode inte följer med i sortering.
  // const newTimeReport: TimeReport[] = timeReports.filter(timereport => timereport.editMode);

  const sort = useSelector((state: any) => state.timeReport.sort)
  const timeReportMeta = useSelector((state: any) => state.timeReport.meta)
  // anv useSelect för att hämta alla projekt från statet
  const projects = useSelector((state: any) => state.app.projects)


  //DOING Behöver hämta projektets namn och id men just nu är det 401-kod från server-hållet.
  //Även en användare behöver tillgång till den sökvägen.

  const [disableBtn, setDisableBtn] = useState(false)
  useEffect(() => {
    if (timeReports.filter((timeReport) => timeReport.editMode && timeReport.id === -1).length) {
      setDisableBtn(true)
    }else{
      setDisableBtn(false)
    }
  }, [timeReports])

  useEffect(() => {
    if (projects.length) {
      dispatch(fetchTimeReportsByUser(user))
    }
  }, [dispatch, user, filter, projects])

  useEffect(() => {
    dispatch(fetchTimeReportsMeta(user))
  }, [dispatch, user])

  const desc = sort === "desc" ? -1 : 1
  const sortedTimeReports = [...timeReports].sort((a, b) => {
    if (b.editMode && b.id === -1) {
      return 1
    }
   return a.time < b.time ? -desc : desc
  })

  const renderButtons = () =>
    isAdmin && (
      <Button
        height={32}
        appearance="primary"
        disabled={disableBtn}
        intent="success"
        onClick={() => showAddNew()}
      >
        Lägg till
      </Button>
    )

  return (
    <ViewWrapper title="Timrapport" renderButtons={renderButtons}>
      {
      <NewTimeSpanSelector yearMonths={timeReportMeta} filter={filter}/>
      }
      <Pane marginTop="20px" display="block">
        {isLoading ? (
         
            <LoadingPane><Spinner margin="auto" /></LoadingPane>
         
        ) : (
          <TimeReportGrid
            timereport={sortedTimeReports}
            projects={projects}
            updateTimeReport={(timeReport: TimeReport) =>
              dispatch(timeReportSlice.actions.updateTimeReport(timeReport))
            }
            cancelTimeReport={(timeReport: TimeReport) =>
              dispatch(timeReportSlice.actions.cancelNew(timeReport))
            }
            saveTimeReport={(timeReport: TimeReport) =>
              dispatch(saveNewTimeReport({ user, timeReport }))
            }
            removeTimeReport={(timeReport: TimeReport) =>
              dispatch(removeTimeReport({ user, timeReport }))
            }
            sort={sort}
            toggleSort={() => dispatch(timeReportSlice.actions.toggleSort())}
            isAdmin={isAdmin}
          />
        )}
      </Pane>
    </ViewWrapper>
  )
}

export default TimeReportView
