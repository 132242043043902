import { Pane } from "evergreen-ui"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ViewWrapper } from "../common/ViewWrapper"
import { fetchYearlyOverview } from "../transactions/slices/transactionsSlice";
import YearlyOverviewGrid from "./YearlyOverviewGrid"

const YearlyOverview = () => {
  const dispatch = useDispatch()
  const [selectedYear, setSelectedYear] = useState(2023);

  useEffect(() => {
    dispatch(fetchYearlyOverview(selectedYear));
  }, [dispatch, selectedYear])


  const startSumOverrides = selectedYear === 2022 ? {
    "fredrik.lindroth@sprinto.se": 202583,
    "carl-johan.danver@sprinto.se": -16200,
    "henning.bergstrom@sprnto.se": 51060,
    "kisslo.shek@sprinto.se": 0.1
  } : selectedYear === 2023 ? {
    "jens.palmqvist@sprinto.se": -211671
  } : {};

  const endSumOverrides = selectedYear === 2023 ? {
    "mikael.lettestad@sprinto.se": 0.1,
    "joel.janson.johansen@sprinto.se": 0.1
  } : {};

  const excludeEmails = {
    2023: ["mikaela.liss@sprinto.se", "jakob.ekbrandt@sprinto.se"]
  }

  const yearlyOverview: any[] = useSelector((state: any) => state.transaction.yearlyOverview)
    .filter((yo: any) => !excludeEmails[selectedYear as keyof typeof excludeEmails]?.includes(yo.email))
    .map((yo: any) => ({ ...yo, startSum: startSumOverrides[yo.email as keyof typeof startSumOverrides] || yo.startSum, endSum: endSumOverrides[yo.email as keyof typeof endSumOverrides] || yo.endSum }))


  return (
    <ViewWrapper title="Årlig översikt">
      <Pane marginTop="20px" display="block">
        <select onChange={(e) => setSelectedYear(parseInt(e.target.value))}>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option selected value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
        {/* {isLoading ? (
          <LoadingPane>
            <Spinner margin="auto" />
          </LoadingPane>
        ) : ( */}
        <YearlyOverviewGrid yearlyOverview={yearlyOverview} />
        {/* )} */}
      </Pane>
    </ViewWrapper>
  )
}

export default YearlyOverview
