import { Pane, Table, TableRow } from "evergreen-ui";
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../app/slices/appSlice";
import { ViewWrapper } from "../common/ViewWrapper";
import { fetchTimeReportsByUser } from "../time-report/slices/timeReportSlice";
import { DateFilter, Employee } from "../types";
import { useNavigate } from 'react-router-dom';

type ProjectViewType = {
  user: Employee;
}

const ProjectListView = ({ user }: ProjectViewType) => {
  const navigate = useNavigate();

  //Hämtar alla projekt i projects  
  const projects: Project[] = useSelector((state: any) => state.app.projects);

  //Funktion för rendera om till ProjectView 
  const getProjectView = (id: number) => {
    navigate(`/project/${id}`);
  }

  return (

    <ViewWrapper title="Projekt">

      <Pane
        clearfix
        display="block"
        borderBottom="1px dotted #ccc"
        marginTop="10px"
      />

      {projects.map(project => <TableRow isSelectable onSelect={() => getProjectView(project.id)}><Table.TextCell>{project.project_name}</Table.TextCell></TableRow>)}

      <Pane
        clearfix
        display="block"
        borderBottom="1px dotted #ccc"
        marginTop="10px"
      />

    </ViewWrapper >
  );
};

export default ProjectListView;
