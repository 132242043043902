import { TabNavigation } from "evergreen-ui";
import { FunctionComponent } from "react";
import { DefaultTab } from '../TimespanSelector/styles';
type TabFilterProps = {
  items: string[];
  selectedIndex: number;
  onSelect: (selectedIndex: number, value?: any) => void;
  inverted?: boolean;
  selectAllOption?: boolean;
};

export const TabFilter: FunctionComponent<TabFilterProps> = ({
  items,
  selectedIndex,
  onSelect,
  inverted,
  selectAllOption,
  children,
}) => {

  return (
    <TabNavigation>
      {!!selectAllOption && (
        <DefaultTab
          key={-1}
          is="span"
          $inverted={!inverted}
          onSelect={() => onSelect(-1)}
          id={-1}
          isSelected={selectedIndex === -1}
        >
          Alla
        </DefaultTab>
      )}
      {items.map((tab, index) => {
        const isSelected = index === selectedIndex;  
        return (
          <DefaultTab
            $inverted={inverted}
            key={tab}
            is="span"
            onSelect={() => onSelect(index, tab)}
            id={tab}
            isSelected={isSelected}
          >
            {tab}
          </DefaultTab>
        );
      })}
      {children}
    </TabNavigation>
  );
};
