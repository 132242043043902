import {
  CaretDownIcon, CaretUpIcon, Table
} from "evergreen-ui";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
import { Project } from "../app/slices/appSlice";
import { getProjectId, getProjectName } from "../helperMethods/appLibrary";
import { SortingHeaderCell, StyledCombobox, TableWrapper } from '../styles/TableStyles';
import { TimeReport } from "../types";
import EditTimeReportRow from "./EditTimeReportRow";
import { tableCellsMaxWidths } from "./layout";
import TimeReportRow from "./TimeReportRow";
type TimeReportGridType = {
  timereport: TimeReport[];
  sort?: "asc" | "desc";
  toggleSort: any;
  updateTimeReport: (timereport: TimeReport) => any;
  cancelTimeReport: (timereport: TimeReport) => any;
  saveTimeReport: (timereport: TimeReport) => any;
  removeTimeReport: (timereport: TimeReport) => any;
  isAdmin: boolean;
  projects: Project[];
};

const TimeReportGrid = ({
  timereport,
  projects,
  sort,
  toggleSort,
  updateTimeReport,
  cancelTimeReport,
  saveTimeReport,
  removeTimeReport,
  isAdmin,
}: TimeReportGridType) => {
  const projectHeader = new Array();
  projectHeader.push({ id: -1, project_name: "ALLA PROJEKT" }, ...projects);
  const [projectFilter, setProjectFilter] = useState(projectHeader[0].id);
  const [totalHours, setTotalHours] = useState(0);



  const sumHours = () => {
    setTotalHours(0);
    timereport
    .filter((report) => report.project_id === projectFilter || projectFilter === -1)
    .map((report)=> setTotalHours((totalHours) => totalHours + Number(report.hours)));

  }

  useEffect(()=>{
    sumHours();
  },[projectFilter, timereport]);


  return (
    <TableWrapper>
      {timereport.length ? <Table>
        <Table.Head padding="0px">
          {!isMobile && (
            <>
              <SortingHeaderCell
                maxWidth={tableCellsMaxWidths.date}
                onClick={() => toggleSort()}
              >
                Datum
                {sort === "desc" ? (
                  <CaretUpIcon size={14} />
                ) : (
                  <CaretDownIcon size={14} />
                )}
              </SortingHeaderCell>
            </>
          )}
          <Table.TextHeaderCell>Beskrivning</Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth={tableCellsMaxWidths.hours}>
            Timmar
          </Table.TextHeaderCell>
          <Table.TextHeaderCell maxWidth={tableCellsMaxWidths.project}>
            <StyledCombobox
              width="100%"
              openOnFocus
              items={projectHeader.map((project) => project.project_name)}
              onChange={(selected: any) => {
                setProjectFilter(getProjectId(selected, projectHeader));
              }}
              selectedItem={getProjectName(projectFilter, projectHeader)}
              size="small"
            />
          </Table.TextHeaderCell>
          <Table.TextHeaderCell
            maxWidth={tableCellsMaxWidths.options}
          ></Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {timereport.map((timereport: TimeReport) =>
            timereport.editMode ? (
              <EditTimeReportRow
                key={timereport.id}
                projects={projects}
                updateTimeReport={updateTimeReport}
                timeReport={timereport}
                saveTimeReport={saveTimeReport}
                cancelTimeReport={cancelTimeReport}
                projectFilter={projectFilter}
              />
            ) : (
              (projectFilter === -1 ||
                projectFilter === timereport.project_id) && (
                <TimeReportRow
                  key={timereport.id}
                  timereport={timereport}
                  onRemove={removeTimeReport}
                  isAdmin={isAdmin}
                />
              )
            )
          )}
        </Table.Body>
        <Table.Head><Table.TextHeaderCell>Totalt antal timmar: {totalHours}</Table.TextHeaderCell></Table.Head>
      </Table> : <>Inga transaktioner</>}
    </TableWrapper>
  );
};

export default TimeReportGrid;
