import { Button, Pane } from "evergreen-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimespanSelector } from "../common/TimespanSelector";
import { ViewWrapper } from "../common/ViewWrapper";
import { Employee, Transaction, TransactionStatus } from "../types";
import { DateFilter, TransactionFilter } from "../types/index";
import { defaultDate } from "../utils/defaultDate";
import isAdminSelector from "../utils/isAdminSelector";
import transactionsSlice, {
  fetchDescriptionSuggestions,
  fetchTransactions,
  fetchTransactionsMeta,
  removeTransaction,
  saveNewTransaction,
  saveUpdatedTransaction
} from "./slices/transactionsSlice";
import TransactionsGrid from "./TransactionsGrid";

type TransactionsViewType = {
  jwtToken: string;
  user: Employee;
};

const TransactionsView = ({ jwtToken, user }: TransactionsViewType) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminSelector);

  const filter: TransactionFilter = useSelector(
    (state: any) => state.transaction.filter
  );

  const transactions: Transaction[] = useSelector(
    (state: any) => state.transaction.entities
  );

  const showAddNew = () => {

    const date = defaultDate(filter.year, filter.month);
    dispatch(transactionsSlice.actions.addNew(date));
  }

  const isLoading: boolean =
    useSelector((state: any) => state.transaction.loading) === "loading";

  const sort = useSelector((state: any) => state.transaction.sort);
  const transactionMeta = useSelector((state: any) => state.transaction.meta);
  const [disableBtn, setDisableBtn] = useState(false)
  useEffect(() => {
    if (transactions.filter((transaction) => transaction.status === TransactionStatus.New && !transaction.id).length) {
      setDisableBtn(true)
    } else {
      setDisableBtn(false)
    }
  }, [transactions])

  useEffect(() => {
    if (transactionMeta) {
      dispatch(fetchTransactions(user));
    }
  }, [dispatch, filter, user, transactionMeta]);

  useEffect(() => {
    if (!transactionMeta) {
      dispatch(fetchTransactionsMeta(user));
    }
    dispatch(fetchDescriptionSuggestions(user));
  }, [dispatch, user, transactionMeta]);

  const renderButtons = () => isAdmin && <>
    <Button height={32} appearance="primary" intent="success" onClick={() => showAddNew()} disabled={disableBtn}>Lägg till</Button>
  </>;
  const desc = sort === "desc" ? -1 : 1;
  const sortedTransactions = [...transactions]
    .sort((a, b) => {
      if (b.status === TransactionStatus.New && !b.id) {
        return 1
      }
      return a.time < b.time ? -desc : desc
    })

  return (
    <ViewWrapper title="Personlig balansräkning" renderButtons={renderButtons}>
      <TimespanSelector
        onChange={(newFilter: DateFilter) =>
          dispatch(transactionsSlice.actions.setFilter(newFilter))
        }
        yearMonths={transactionMeta}
        filter={filter}
      />
      <Pane marginTop="20px" display="block">
        <TransactionsGrid
          transactions={sortedTransactions}
          updateTransaction={(transaction: Transaction) => dispatch(transactionsSlice.actions.updateTransaction(transaction))}
          cancelTransaction={(transaction: Transaction) => dispatch(transactionsSlice.actions.cancelNew(transaction))}
          saveTransaction={(transaction: Transaction) => dispatch(saveNewTransaction({ user, transaction }))}
          undoTransaction={(transaction: Transaction) => dispatch(transactionsSlice.actions.undoImportedTransaction(transaction))}
          removeTransaction={(transaction: Transaction) => dispatch(removeTransaction({ user, transaction }))}
          saveUpdatedTransaction={(transaction: Transaction) => dispatch(saveUpdatedTransaction({ user, transaction }))}
          sort={sort}
          toggleSort={() => dispatch(transactionsSlice.actions.toggleSort())}
          setDescriptionFilter={(description: string) => dispatch(transactionsSlice.actions.setDescriptionFilter(description))}
          descriptionFilter={filter.description}
          isAdmin={isAdmin}
          isLoading={isLoading}
        />
      </Pane>
    </ViewWrapper>
  );
};

export default TransactionsView;
