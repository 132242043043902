import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import isAdminSelector from "../../utils/isAdminSelector";
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';


export const IsAdminRoute = ({ children }: { children: JSX.Element }) => {
  const isAdmin = useSelector(isAdminSelector); //false;

  if (!isAdmin) {
    return <ErrorMessage
      title={
        "401 - Vi är väldigt ledsna, du har ingen behörighet till sidan och vi kan verkligen inte göra nånting. Ring Jonatan."
      }
    />
  }

  return children;
};
